:root {
  /* Raw palette */
  --BLACK-1: #999;
  --BLACK-2: #666;
  --BLACK-3: #333;
  --BLACK-4: #111;
  --BLACK-5: #000;

  --WHITE-1: #fff;
  --WHITE-2: #fefefe;
  --WHITE-3: #fcfcfc;
  --WHITE-4: #fafafa;
  --WHITE-5: #f0f0f0;

  --GREY-1: #eee;
  --GREY-2: #ddd;
  --GREY-3: #ccc;
  --GREY-4: #bbb;
  --GREY-5: #aaa;

  --BRAND-GREEN-1: #5bbe78;
  --BRAND-GREEN-2: #558f62;
  --BRAND-GREEN-3: #3e4440;
  --BRAND-ACCENT-1: #e16198;

  /* Global colors */
  --color-content: var(--BRAND-GREEN-3);
  --color-bg: var(--WHITE-3);
  --color-accent: var(--BRAND-ACCENT-1);
  --color-primary-light: var(--BRAND-GREEN-1);
  --color-primary-dark: var(--BRAND-GREEN-2);

  /* Fonts */
  --font-family-serif: 'Alice', serif;
  --font-family-sans: 'Josefin Sans', sans-serif;
  --font-size-xs: 14px;
  --font-size-s: 18px;
  --font-size-m: 22px;
  --font-size-l: 30px;
  --font-size-xl: 50px;
  --font-size-xxl: 100px;
  --font-size-xxxl: 150px;
}
