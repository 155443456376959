html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-content);
  font-family: var(--font-family-serif);
  font-size: var(--font-size-m);
  line-height: 1;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

a:link,
a:visited,
a:active,
a:hover {
  color: var(--color-primary-light);
  text-decoration: underline;
}

a:link,
a:visited,
a:active {
  /* text-decoration: none; */
}

a:hover {
  color: var(--color-primary-dark);
}
